define("discourse/plugins/discourse-presence/discourse/components/topic-presence-display", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "truth-helpers", "discourse/components/user-link", "discourse/helpers/avatar", "discourse/helpers/helper-fn", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _truthHelpers, _userLink, _avatar, _helperFn, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicPresenceDisplay extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "presence", [_service.service]))();
    #presence = (() => (dt7948.i(this, "presence"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "replyChannel", [_tracking.tracked]))();
    #replyChannel = (() => (dt7948.i(this, "replyChannel"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "whisperChannel", [_tracking.tracked]))();
    #whisperChannel = (() => (dt7948.i(this, "whisperChannel"), void 0))();
    setupReplyChannel = (() => (0, _helperFn.default)((_, on) => {
      const {
        topic
      } = this.args;
      if (!topic) {
        return;
      }
      const name = `/discourse-presence/reply/${topic.id}`;
      const replyChannel = this.presence.getChannel(name);
      this.replyChannel = replyChannel;
      replyChannel.subscribe();
      on.cleanup(() => replyChannel.unsubscribe());
    }))();
    setupWhisperChannel = (() => (0, _helperFn.default)((_, on) => {
      const {
        topic
      } = this.args;
      const {
        whisperer
      } = this.currentUser;
      if (!topic || !whisperer) {
        return;
      }
      const name = `/discourse-presence/whisper/${topic.id}`;
      const whisperChannel = this.presence.getChannel(name);
      this.whisperChannel = whisperChannel;
      whisperChannel.subscribe();
      on.cleanup(() => whisperChannel.unsubscribe());
    }))();
    get users() {
      const replyUsers = this.replyChannel?.users || [];
      const whisperUsers = this.whisperChannel?.users || [];
      return [...replyUsers, ...whisperUsers].filter(u => u.id !== this.currentUser.id);
    }
    static #_5 = (() => dt7948.n(this.prototype, "users", [_tracking.cached]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{this.setupReplyChannel}}
        {{this.setupWhisperChannel}}
    
        {{#if (gt this.users.length 0)}}
          <div class="presence-users">
            <div class="presence-avatars">
              {{#each this.users as |user|}}
                <UserLink @user={{user}}>
                  {{avatar user imageSize="small"}}
                </UserLink>
              {{/each}}
            </div>
    
            <span class="presence-text">
              <span class="description">
                {{i18n "presence.replying_to_topic" count=this.users.length}}
              </span>
              <span class="wave">
                <span class="dot">.</span>
                <span class="dot">.</span>
                <span class="dot">.</span>
              </span>
            </span>
          </div>
        {{/if}}
      
    */
    {
      "id": "jkMEHMUr",
      "block": "[[[1,\"\\n    \"],[1,[30,0,[\"setupReplyChannel\"]]],[1,\"\\n    \"],[1,[30,0,[\"setupWhisperChannel\"]]],[1,\"\\n\\n\"],[41,[28,[32,0],[[30,0,[\"users\",\"length\"]],0],null],[[[1,\"      \"],[10,0],[14,0,\"presence-users\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"presence-avatars\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"users\"]]],null]],null],null,[[[1,\"            \"],[8,[32,1],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[32,2],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"presence-text\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"description\"],[12],[1,\"\\n            \"],[1,[28,[32,3],[\"presence.replying_to_topic\"],[[\"count\"],[[30,0,[\"users\",\"length\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,1],[14,0,\"wave\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"user\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-presence/discourse/components/topic-presence-display.js",
      "scope": () => [_truthHelpers.gt, _userLink.default, _avatar.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicPresenceDisplay;
});