define("discourse/plugins/chat/discourse/services/chat-channels-manager", ["exports", "@glimmer/tracking", "@ember/service", "@ember-compat/tracked-built-ins", "rsvp", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/models/chat-channel"], function (_exports, _tracking, _service, _trackedBuiltIns, _rsvp, _ajaxError, _decorators, _chatChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DIRECT_MESSAGE_CHANNELS_LIMIT = 50;

  /*
    The ChatChannelsManager service is responsible for managing the loaded chat channels.
    It provides helpers to facilitate using and managing loaded channels instead of constantly
    fetching them from the server.
  */

  class ChatChannelsManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatSubscriptionsManager", [_service.service]))();
    #chatSubscriptionsManager = (() => (dt7948.i(this, "chatSubscriptionsManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "_cached", [_tracking.tracked], function () {
      return new _trackedBuiltIns.TrackedObject();
    }))();
    #_cached = (() => (dt7948.i(this, "_cached"), void 0))();
    async find(id) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        fetchIfNotFound: true
      };
      const existingChannel = this.#findStale(id);
      if (existingChannel) {
        return _rsvp.default.resolve(existingChannel);
      } else if (options.fetchIfNotFound) {
        return this.#find(id);
      } else {
        return _rsvp.default.resolve();
      }
    }
    get channels() {
      return Object.values(this._cached);
    }
    static #_9 = (() => dt7948.n(this.prototype, "channels", [_tracking.cached]))();
    store(channelObject) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let model;
      if (!options.replace) {
        model = this.#findStale(channelObject.id);
      }
      if (!model) {
        if (channelObject instanceof _chatChannel.default) {
          model = channelObject;
        } else {
          model = _chatChannel.default.create(channelObject);
        }
        this.#cache(model);
      }
      if (channelObject.meta?.message_bus_last_ids?.channel_message_bus_last_id !== undefined) {
        model.channelMessageBusLastId = channelObject.meta.message_bus_last_ids.channel_message_bus_last_id;
      }
      return model;
    }
    async follow(model) {
      this.chatSubscriptionsManager.startChannelSubscription(model);
      if (!model.currentUserMembership.following) {
        return this.chatApi.followChannel(model.id).then(membership => {
          model.currentUserMembership = membership;
          return model;
        });
      } else {
        return model;
      }
    }
    async unfollow(model) {
      try {
        this.chatSubscriptionsManager.stopChannelSubscription(model);
        model.currentUserMembership = await this.chatApi.unfollowChannel(model.id);
        return model;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    async markAllChannelsRead() {
      // The user tracking state for each channel marked read will be propagated by MessageBus
      return this.chatApi.markAllChannelsAsRead();
    }
    static #_10 = (() => dt7948.n(this.prototype, "markAllChannelsRead", [(0, _decorators.debounce)(300)]))();
    remove(model) {
      if (!model) {
        return;
      }
      this.chatSubscriptionsManager.stopChannelSubscription(model);
      delete this._cached[model.id];
    }
    get hasThreadedChannels() {
      return this.allChannels?.some(channel => channel.threadingEnabled);
    }
    static #_11 = (() => dt7948.n(this.prototype, "hasThreadedChannels", [_tracking.cached]))();
    get allChannels() {
      return [...this.publicMessageChannels, ...this.directMessageChannels].sort((a, b) => {
        return b?.currentUserMembership?.lastViewedAt?.localeCompare?.(a?.currentUserMembership?.lastViewedAt);
      });
    }
    get publicMessageChannels() {
      return this.channels.filter(channel => channel.isCategoryChannel && channel.currentUserMembership.following).sort((a, b) => a?.slug?.localeCompare?.(b?.slug));
    }
    static #_12 = (() => dt7948.n(this.prototype, "publicMessageChannels", [_tracking.cached]))();
    get publicMessageChannelsWithActivity() {
      return this.publicMessageChannels.filter(channel => channel.hasUnread);
    }
    get publicMessageChannelsByActivity() {
      return this.#sortChannelsByActivity(this.publicMessageChannels);
    }
    get directMessageChannels() {
      return this.#sortDirectMessageChannels(this.channels.filter(channel => {
        const membership = channel.currentUserMembership;
        return channel.isDirectMessageChannel && membership.following;
      }));
    }
    static #_13 = (() => dt7948.n(this.prototype, "directMessageChannels", [_tracking.cached]))();
    get directMessageChannelsWithActivity() {
      return this.directMessageChannels.filter(channel => channel.hasUnread);
    }
    get truncatedDirectMessageChannels() {
      return this.directMessageChannels.slice(0, DIRECT_MESSAGE_CHANNELS_LIMIT);
    }
    async #find(id) {
      try {
        const result = await this.chatApi.channel(id);
        return this.store(result.channel);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    get publicMessageChannelsEmpty() {
      return this.publicMessageChannels?.length === 0 && this.chatStateManager.hasPreloadedChannels;
    }
    get displayPublicChannels() {
      if (!this.siteSettings.enable_public_channels) {
        return false;
      }
      if (!this.chatStateManager.hasPreloadedChannels) {
        return false;
      }
      if (this.publicMessageChannelsEmpty) {
        return this.currentUser?.staff || this.currentUser?.has_joinable_public_channels;
      }
      return true;
    }
    #cache(channel) {
      if (!channel) {
        return;
      }
      this._cached[channel.id] = channel;
    }
    #findStale(id) {
      return this._cached[id];
    }
    #sortChannelsByActivity(channels) {
      return channels.sort((a, b) => {
        const stats = {
          a: {
            urgent: a.tracking.mentionCount + a.tracking.watchedThreadsUnreadCount,
            unread: a.tracking.unreadCount + a.unreadThreadsCountSinceLastViewed
          },
          b: {
            urgent: b.tracking.mentionCount + b.tracking.watchedThreadsUnreadCount,
            unread: b.tracking.unreadCount + b.unreadThreadsCountSinceLastViewed
          }
        };

        // if both channels have urgent count, sort by slug
        // otherwise prioritize channel with urgent count
        if (stats.a.urgent > 0 && stats.b.urgent > 0) {
          return a.slug?.localeCompare?.(b.slug);
        }
        if (stats.a.urgent > 0 || stats.b.urgent > 0) {
          return stats.a.urgent > stats.b.urgent ? -1 : 1;
        }

        // if both channels have unread messages or threads, sort by slug
        // otherwise prioritize channel with unread count
        if (stats.a.unread > 0 && stats.b.unread > 0) {
          return a.slug?.localeCompare?.(b.slug);
        }
        if (stats.a.unread > 0 || stats.b.unread > 0) {
          return stats.a.unread > stats.b.unread ? -1 : 1;
        }
        return a.slug?.localeCompare?.(b.slug);
      });
    }
    #sortDirectMessageChannels(channels) {
      return channels.sort((a, b) => {
        if (!a.lastMessage.id) {
          return 1;
        }
        if (!b.lastMessage.id) {
          return -1;
        }
        const aUrgent = a.tracking.unreadCount + a.tracking.mentionCount + a.tracking.watchedThreadsUnreadCount;
        const bUrgent = b.tracking.unreadCount + b.tracking.mentionCount + b.tracking.watchedThreadsUnreadCount;
        const aUnread = a.unreadThreadsCountSinceLastViewed;
        const bUnread = b.unreadThreadsCountSinceLastViewed;

        // if both channels have urgent count, sort by last message date
        if (aUrgent > 0 && bUrgent > 0) {
          return new Date(a.lastMessage.createdAt) > new Date(b.lastMessage.createdAt) ? -1 : 1;
        }

        // otherwise prioritize channel with urgent count
        if (aUrgent > 0 || bUrgent > 0) {
          return aUrgent > bUrgent ? -1 : 1;
        }

        // if both channels have unread threads, sort by last thread reply date
        if (aUnread > 0 && bUnread > 0) {
          return a.lastUnreadThreadDate > b.lastUnreadThreadDate ? -1 : 1;
        }

        // otherwise prioritize channel with unread thread count
        if (aUnread > 0 || bUnread > 0) {
          return aUnread > bUnread ? -1 : 1;
        }

        // read channels are sorted by last message date
        return new Date(a.lastMessage.createdAt) > new Date(b.lastMessage.createdAt) ? -1 : 1;
      });
    }
  }
  _exports.default = ChatChannelsManager;
});